import * as React from "react"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Layout from "../components/ui/layout"
import { makeStyles } from "@material-ui/core/styles"

import { navigate } from "gatsby"
import { UserContext } from "../contexts"
import { setUser } from "../contexts/actions"

const useStyles = makeStyles(theme => ({
  outBox: {
    width: "100%",
    minHeight: "calc(100vh  - 66px)",
    position: "relative",
  },

  inBox: {
    width: "100%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
}))
const LogOut = () => {
  const { dispatchUser, defaultUser } = React.useContext(UserContext)
  const classes = useStyles()

  const handleLogout = () => {
    dispatchUser(setUser(defaultUser))
    navigate("/")
  }

  return (
    <Layout footerOne={false} footerTwo={true}>
      <Box classes={{ root: classes.outBox }}>
        <Box classes={{ root: classes.inBox }}>
          <Typography variant="body1" paragraph>
            {"Are you sure you want to logout?"}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={handleLogout}
            size="large"
            disableElevation
          >
            <Typography variant="body1">{"Logout"}</Typography>
          </Button>
        </Box>
      </Box>
    </Layout>
  )
}

export default LogOut
